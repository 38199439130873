'use client';

import { usePathname } from 'next/navigation';

import { Box, Button, Center, Image, Text } from '@/components';
import { useTranslation } from '@/hooks';
import BackgroundMain from '@/images/404/backgroundMain.png';
import FiveOThree from '@/images/503/503.png';
import { routes } from '@/statics';
import { getBackgroundImage, getImageProps } from '@/utils';

const UnpredictedError: React.FC = () => {
  const { t } = useTranslation('pages');
  const {
    props: { srcSet },
  } = getImageProps({ alt: '', src: BackgroundMain });
  const backgroundImage = getBackgroundImage(srcSet);
  const pathname = usePathname();

  return (
    <Box alignItems="center" flexDir="column" display="flex" h="100%">
      <Box
        bgImage={`/**/${backgroundImage}`}
        w="100%"
        h="100vh"
        bgSize="cover"
        bgPosition="center"
        bgRepeat="no-repeat"
      >
        <Center mb="56px">
          <Text
            mt={{ base: '75px', md: '86px' }}
            fontSize={{ base: 'md', md: '2xl' }}
            fontWeight={500}
            color="text"
          >
            {t('fiveOThree.unreachable')}
          </Text>
        </Center>
        <Box width="100%" display="flex" justifyContent="center">
          <Center mt="5rem" width={{ base: '250px', md: '350px', lg: '530px' }}>
            <Image alt="503 error" src={FiveOThree} />
          </Center>
        </Box>
        <Center mt="7rem" mb="7rem" flexDir="column" gap="5">
          <Button
            onClick={() => window.location.reload()}
            variant="outline"
            size={{ base: 'xl', md: 'xxl' }}
            colorScheme="gray"
            _hover={{
              bg: `transparent`,
            }}
          >
            {t('fiveOThree.tryAgain')}
          </Button>
          {pathname === routes.home.url ? (
            <></>
          ) : (
            <Button
              onClick={() => window.location.replace(routes.home.url)}
              variant="outline"
              size={{ base: 'xl', md: 'xxl' }}
              colorScheme="gray"
              _hover={{
                bg: `transparent`,
              }}
            >
              {t('fiveOThree.backToHome')}
            </Button>
          )}
        </Center>
      </Box>
    </Box>
  );
};

export default UnpredictedError;
