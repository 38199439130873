import cloneDeep from 'lodash/cloneDeep';

import i18n from '@/i18n';
import routesOrigin from '@/statics/routes-origin';

const AUTH = '/auth';
const IDENTITY = '/identity';
const DASHBOARD = '/dashboard';

const routes = cloneDeep(routesOrigin);

for (const key in routes) {
  const route = routes[key as keyof typeof routes] as {
    url: string;
    title?: string;
    href: (...data: any) => void;
  };
  if (route?.title) {
    route.title = i18n.t(route.title);
  }
}

const authRoutPrefixes = [AUTH];
const privateRoutPrefixes = [DASHBOARD, IDENTITY];

export { authRoutPrefixes, privateRoutPrefixes, routes };
