const AUTH = '/auth';
const IDENTITY = '/identity';
const DASHBOARD = '/dashboard';
const CHECKOUT = '/checkout';
const BRANDS = '/brands';

const routesOrigin = {
  categories: {
    url: '/categories',
    title: 'pages.categories.liaProducts',
  },
  category: {
    url: '/categories/[id]',
    href: (id: number): string => `/categories/${id}`,
  },
  subCategory: {
    url: '/categories/[id]/sub/[subId]',
    href: (id: number, subId: number): string =>
      `/categories/${id}/sub/${subId}`,
  },
  product: {
    url: '/products/[id]',
    href: (code: number): string => `/products/${code}`,
  },
  home: { url: '/', title: 'pages.home.home' },
  signUp: { url: `${AUTH}/sign-up`, title: 'pages.home.signUp' },
  signIn: { url: `${AUTH}/sign-in`, title: 'pages.home.signIn' },
  forgetPassword: {
    url: `${AUTH}/forget-password`,
    title: 'pages.home.forgetPassword',
  },
  faq: { url: '/faq', title: 'pages.faq.faq' },
  aboutUs: {
    url: '/about-us',
    title: 'pages.aboutUs.aboutUs',
  },
  contactUs: {
    url: '/contact-us',
    title: 'pages.contactUs.contactUs',
  },
  executiveTeam: {
    url: '/executive-team',
    title: 'pages.executiveTeam.executiveTeam',
  },
  jobPositions: {
    url: '/job-positions',
    title: 'pages.jobPositions.jobPositions',
  },
  checkout: {
    url: CHECKOUT,
    title: 'pages.checkout.checkout',
  },
  jobPosition: {
    title: 'pages.jobPositions.jobPosition',
    url: '/job-positions/[id]',
    href: (id: string): string => `/job-positions/${id}`,
  },
  blogs: { url: '/blogs', title: 'pages.blogs.blog' },
  blog: {
    url: '/blogs/[code]',
    href: (code: string): string => `/blogs/${code}`,
  },
  dashboard: {
    url: DASHBOARD,
    title: 'pages.dashboard.dashboard',
  },
  rollbacks: {
    url: `${DASHBOARD}/rollbacks`,
    title: 'pages.rollbacks.rollbackRequest',
  },
  recruitmentRate: {
    url: `${DASHBOARD}/recruitment-rate`,
    title: 'pages.recruitmentRate.recruitmentRate',
  },
  complaints: {
    url: '/complaints',
    title: 'pages.complaints.complaints',
  },
  personalInformation: {
    url: `${DASHBOARD}/personal-information`,
    title: 'pages.personalInformation.personalInformation',
  },
  tickets: {
    url: `${DASHBOARD}/tickets`,
    title: 'pages.tickets.tickets',
  },
  newTicket: {
    url: `${DASHBOARD}/tickets/new-ticket`,
    title: 'pages.newTicket.newTicket',
  },
  commission: {
    url: `${DASHBOARD}/commission`,
    title: 'pages.commission.commission',
  },
  nextMonthRetailDiscount: {
    url: `${DASHBOARD}/next-month-retail-discount`,
    title: 'pages.nextMonthRetailDiscount.nextMonthRetailDiscount',
  },
  addresses: {
    url: `${DASHBOARD}/addresses`,
    title: 'pages.addresses.addresses',
  },
  orders: {
    url: `${DASHBOARD}/orders`,
    title: 'pages.orders.orders',
  },
  order: {
    url: `${DASHBOARD}/orders/[code]`,
    href: (code: string): string => `${DASHBOARD}/orders/${code}`,
    title: 'pages.order.order',
  },
  resendOrder: {
    url: `${DASHBOARD}/orders/[code]/resend`,
    href: (code: string): string => `${DASHBOARD}/orders/${code}/resend`,
    title: 'pages.resend.resend',
  },
  identitySubmitEmta: {
    url: `${IDENTITY}/submit-emta`,
    title: 'pages.identity.submitEmta.submitEmta',
  },
  identitySubmitOtp: {
    url: `${IDENTITY}/submit-otp`,
    title: 'pages.identity.submitOtp.submitOtp',
  },
  identityUploadFile: {
    url: `${IDENTITY}/upload-file`,
    title: 'pages.identity.uploadFile.uploadFile',
  },
  identityBecomeAgent: {
    url: `${IDENTITY}/become-agent`,
    title: 'pages.identity.becomeAgent.becomeAgent',
  },
  staticPages: {
    title: 'pages.staticPage.staticPage',
    url: '/static-pages/[param]',
    href: (param: string): string => `/static-pages/${param}`,
  },
  favorites: {
    title: 'pages.favorites.title',
    url: `${DASHBOARD}/favorites`,
  },
  search: {
    title: 'pages.search.searchResult',
    url: '/search',
  },
  promotions: {
    url: '/promotions/[code]',
    href: (code: string): string => `/promotions/${code}`,
  },
  viewTicket: {
    url: `${DASHBOARD}/tickets/[id]`,
    href: (id: string): string => `${DASHBOARD}/tickets/${id}`,
    title: 'pages.tickets.ticket',
  },
  brand: {
    url: `${BRANDS}/[code]`,
    href: (code: string): string => `${BRANDS}/${code}`,
    title: 'pages.brands.brand',
  },
  retail: {
    url: `${DASHBOARD}/retail`,
    title: 'pages.retail.retail',
  },
  manageLprs: {
    url: `${DASHBOARD}/manage-lprs`,
    title: 'pages.manageLPRS.manageMyLPRS',
  },
  manageExamsLPRS: {
    url: `${DASHBOARD}/manage-lprs/manage-exams`,
    title: 'pages.manageLPRS.manageExams',
  },
  manageOrderLPRS: {
    url: `${DASHBOARD}/manage-lprs/manage-exams/[code]`,
    href: (code: string): string =>
      `${DASHBOARD}/manage-lprs/manage-exams/${code}`,
    title: 'pages.manageLPRS.manageExams',
  },
  manageLinksLPRS: {
    url: `${DASHBOARD}/manage-lprs/manage-links`,
    title: 'pages.manageLPRS.manageLinks',
  },
  manageConsultationsLPS: {
    url: `${DASHBOARD}/manage-lprs/manage-consultations`,
    title: 'pages.manageLPRS.managementOfConsultations',
  },
  notifications: {
    url: `${DASHBOARD}/notifications`,
    title: 'pages.notifications.notifications',
  },
  delaverse: {
    url: `${DASHBOARD}/ai`,
    title: 'pages.delaverse.delaverse',
  },
};

const authRoutPrefixes = [AUTH];
const privateRoutPrefixes = [DASHBOARD, IDENTITY];

export { authRoutPrefixes, privateRoutPrefixes };

export default routesOrigin;
